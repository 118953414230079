.quill-editor h1,
.quill-editor h2,
.quill-editor h3,
.quill-editor h4,
.quill-editor h5,
.quill-editor h6 {
    line-height: 1.2;
    margin-top: 0;
}

.ql-editor h1 {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.quill-editor h2 {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.quill-editor h3 {
    font-size: 1.75em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.quill-editor h4 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.quill-editor h5 {
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.quill-editor h6 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.5em;
}