.bg-image-container {
    /* Existing styles... */
    animation: fade-in 1s ease-in-out; /* Remove infinite alternate */
    animation-play-state: paused; /* Start animation paused */
  }
  
  .bg-image-container.animate {
    animation-play-state: running; /* Trigger animation on class addition */
  }
  
  @keyframes fade-in {
    from {
      opacity: 0.7;
    }
  
    to {
      opacity: 1;
    }
  }